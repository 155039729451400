<template>
  <div class="h-full min-h-0" v-if="useContentGuard.canSee('organisation:proces')">
    <div class="h-full">
      <div class="flex h-full flex-col">
        <div>
          <div class="flex flex-row justify-between">
            <div class="flex flex-row items-center">
              <span class="mr-6">
                <backButton />
              </span>
              <basePageTitle
                v-if="!useProjectDetails.loadHandler.states.get_details"
                :title="useProjectDetails.project.value.name"
              >
                <p class="text-slate-500 text-xs">
                  {{ useProjectDetails.project.value.description }}
                </p>
              </basePageTitle>

              <base-loader
                class="text-xl text-slate-500 my-auto"
                :loading="useProjectDetails.loadHandler.states.get_details"
              />
            </div>
          </div>

          <baseTabs
            :handler="useTabs"
            v-show="!useProjectDetails.loadHandler.states.get_details"
            @changed="onTabChange"
          >
            <template v-slot:panel-overzicht>
              <tabProjectOverview />
            </template>
            <template v-slot:panel-maatregelen>
              <tabProjectMeasures />
            </template>
            <template v-slot:panel-steps_tasks>
              <tabProjectSteps :handler="useProjectDetails" />
            </template>
            <template v-slot:panel-data>
              <tabProjectData />
            </template>
          </baseTabs>
        </div>
      </div>
    </div>

    <confirmationModal :handler="confirm" />
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import projectDetailHandler from '@/use/projectDetailHandler'
import contentGuardHandler from '@/use/contentGuardHandler'
import tabHandler from '@/use/tabHandler'
import confirmationHandler from '@/use/confirmationHandler'
import confirmationModal from '@/components/extended/confirmationModal.vue'
import tabProjectOverview from '@/views/tabs/tabProjectOverview.vue'
import tabProjectMeasures from '@/views/tabs/tabProjectMeasures.vue'
import tabProjectData from '@/views/tabs/tabProjectData.vue'
import tabProjectSteps from '@/views/tabs/tabProjectSteps.vue'
import { useRoute } from 'vue-router'

const useProjectDetails = projectDetailHandler()
const useContentGuard = contentGuardHandler()
const useTabs = tabHandler()
const confirm = confirmationHandler()
const currentTab = ref(null)
const route = useRoute()

const tabOptions = {
  tabs: [
    { id: 'overzicht', label: 'Overzicht', active: true },
    { id: 'maatregelen', label: 'Maatregelen', active: true },
    { id: 'steps_tasks', label: 'Stappen & Taken', active: true },
    { id: 'data', label: 'Data', active: true },
  ],
}

onMounted(() => {
  useTabs.create(tabOptions)
  useProjectDetails.getDetails({ id: route.params.projectId })
})

function onTabChange(changeInfo) {
  const { target } = changeInfo
  currentTab.value = target
}
</script>
