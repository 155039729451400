<template>
  <div class="my-8">
    <basePageTitle title="Data" class="my-8" />
  </div>
  <baseLoader class="text-slate-500" :loading="useExportData.loadHandler.states.get_records" />

  <baseTree :handler="treeAlgemeen" />
  <baseTree :handler="treeSoortWoning" />
  <baseTree :handler="treeInstallatiesHuidig" />
  <baseTree :handler="treeInstallatiesNieuw" />
  <baseTree :handler="treeResultsHuidig" />
  <baseTree :handler="treeResultsNieuw" />
  <baseTree :handler="treeResultsHuidig20" />
  <baseTree :handler="treeResultsNieuw20" />
  <baseTree :handler="treeMaatregelCategorieen" />

  <baseButton class="mt-2" size="small" @action="exportData">Exporteer</baseButton>
</template>

<script setup>
import { onMounted, reactive } from 'vue'
import projectDetailHandler from '@/use/projectDetailHandler'
import tabHandler from '@/use/tabHandler'
import treeHandler from '@/use/treeHandler'
import exportProjectDataHandler from '@/use/exportProjectDataHandler'
import lookupHandler from '@/use/lookupHandler'

const useProjectDetails = projectDetailHandler()
const useTabs = tabHandler()
const useExportData = exportProjectDataHandler()
const useLookup = lookupHandler()

const maatregelCategorieenCheckData = reactive([
  {
    id: 'maatregel_categorieen',
    label: 'Maatregelcategorieen',
    container: true,
    checked: false,
    collapsed: true,
    children: [],
  },
])

const treeAlgemeen = treeHandler(useLookup.algemeenCheckData)
const treeSoortWoning = treeHandler(useLookup.soortWoningCheckData)
const treeInstallatiesHuidig = treeHandler(useLookup.installatiesHuidigCheckData)
const treeInstallatiesNieuw = treeHandler(useLookup.installatiesNieuwCheckData)
const treeResultsHuidig20 = treeHandler(useLookup.resultsHuidig20CheckData)
const treeResultsNieuw20 = treeHandler(useLookup.resultsNieuw20CheckData)
const treeResultsHuidig = treeHandler(useLookup.resultsHuidigCheckData)
const treeResultsNieuw = treeHandler(useLookup.resultsNieuwCheckData)
const treeMaatregelCategorieen = treeHandler(maatregelCategorieenCheckData)

onMounted(() => {
  useTabs.create({
    tabs: [
      { id: 'overzicht', label: 'Overzicht', active: true },
      { id: 'maatregelen', label: 'Maatregelen', active: true },
      { id: 'steps_tasks', label: 'Stappen & Taken', active: true },
      { id: 'data', label: 'Data', active: true },
    ],
  })
})

function exportData() {
  const combinedPaths = {}
  const combinedLabels = {}

  const mergeCheckedPaths = (checkedPathsObject) => {
    Object.assign(combinedPaths, checkedPathsObject.paths)
    Object.assign(combinedLabels, checkedPathsObject.labels)
  }

  const trees = [
    treeAlgemeen,
    treeSoortWoning,
    treeInstallatiesHuidig,
    treeInstallatiesNieuw,
    treeResultsHuidig20,
    treeResultsNieuw20,
    treeResultsHuidig,
    treeResultsNieuw,
    treeMaatregelCategorieen,
  ]

  trees.forEach((tree) => mergeCheckedPaths(tree.checkedPaths.value))

  const treeData = { paths: combinedPaths, labels: combinedLabels }

  // TODO: useProjectdetails.project.value is heir leeg omdat in deze tab een projecthandler wordt aangemaakt. Getdetails of gedeelde handler nodig.
  useExportData.getProjectSurveys(useProjectDetails.project.value.id, treeData, useProjectDetails.project)
}
</script>
