<template>
  <div>
    <div v-for="(step, stepIndex) in steps" :key="step.id" class="flex flex-row w-full text-slate-500 mb-2">
      <base-collapse class="w-full divide-y bg-white rounded shadow-md" :initial_open="true">
        <template #header>
          <span class="mr-2 font-semibold text-lg">{{ stepIndex + 1 }}. {{ step.name }}</span>
          <baseButton size="small" @action="deleteStep(step.id)" class="mr-2" variant="danger">
            <TrashIcon class="h-4 w-4" />
          </baseButton>
        </template>
        <template #body>
          <baseTable
            :fields="taskFields"
            :records="step.default_tasks"
            :records-per-page="10"
            :sortable="true"
            :addButton="true"
            @add="openAddDefaultTaskModal(step.id)"
            :editButton="true"
            @edit="openEditTaskModal"
            :deleteButton="true"
            @delete="deleteTask"
            keyField="id"
          />
        </template>
      </base-collapse>
    </div>

    <div class="rounded shadow-md bg-white w-full border-slate-100 border p-4 mb-4">
      <baseButton variant="toned" size="small" @action="openAddStepModal">
        <PlusIcon class="h-4 w-4" />
      </baseButton>
      <span class="ml-4 text-lg font-semibold">Stap Toevoegen</span>
    </div>

    <baseModal v-if="showAddStepModal" @close="showAddStepModal = false" :title="$translate('Add')">
      <baseForm :handler="addStepForm" />
      <template #buttons>
        <baseButton @action="addStep">{{ $translate('Add') }}</baseButton>
      </template>
    </baseModal>

    <baseModal v-if="showAddDefaultTaskModal" @close="showAddDefaultTaskModal = false" :title="$translate('Add')">
      <baseForm :handler="addDefaultTaskForm" />
      <template #buttons>
        <baseButton @action="addDefaultTask">{{ $translate('Add') }}</baseButton>
      </template>
    </baseModal>

    <baseModal v-if="showEditTaskModal" @close="showEditTaskModal = false" :title="$translate('Edit')">
      <baseForm :handler="editTaskForm" />
      <template #buttons>
        <baseButton @action="editTask">{{ $translate('Edit') }}</baseButton>
      </template>
    </baseModal>

    <confirmationModal :handler="confirm" />
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import confirmationHandler from '@/use/confirmationHandler'
import confirmationModal from '@/components/extended/confirmationModal.vue'
import apiHandler from '@/use/apiHandler'
import formHandler from '@/use/formHandler'
import { PlusIcon, TrashIcon } from '@heroicons/vue/outline'

const props = defineProps({
  handler: {
    type: Object,
    default: () => ({}),
  },
})

const route = useRoute()
const confirm = confirmationHandler()
const useApi = apiHandler()

const steps = ref([])
const showAddStepModal = ref(false)
const showAddDefaultTaskModal = ref(false)
const addStepForm = formHandler()
const addDefaultTaskForm = formHandler()
const users = ref([])
const showEditTaskModal = ref(false)
const editTaskForm = formHandler()
const currentEditingTask = ref(null)

const taskFields = [
  { label: 'ID', key: 'id', type: 'string', table: false },
  { label: 'Taak', key: 'name', type: 'string' },
  { label: 'Omschrijving', key: 'description', type: 'string' },
]

onMounted(async () => {
  try {
    const [usersResponse, stepsResponse] = await Promise.all([
      useApi.requestV2('get', 'v1/organizations/users'),
      useApi.requestV2('get', `v1/projects/${route.params.projectId}/steps/default_tasks`),
    ])

    users.value = usersResponse.data.map((user) => ({ label: user.name, value: user.id }))
    steps.value = stepsResponse.data.map((step) => ({
      ...step,
      default_tasks: step.default_tasks.map((task) => ({
        ...task,
        user_names: task.users.map((user) => user.name).join(', '),
      })),
    }))

    initializeForms()
  } catch (error) {
    console.error('Error fetching data:', error)
  }
})

function initializeForms() {
  const addStepFormFields = [
    { key: 'name', label: 'Name', type: 'string' },
    { key: 'description', label: 'Description', type: 'string' },
    {
      key: 'project_id',
      label: 'Project',
      type: 'select',
      disabled: true,
      options: [{ label: props.handler.project.value.name, value: props.handler.project.value.id }],
    },
  ]

  addStepForm.create({
    type: 'add',
    fields: addStepFormFields,
    data: { project_id: route.params.projectId },
  })

  const addDefaultTaskFormFields = [
    { key: 'name', label: 'Name', type: 'string' },
    { key: 'description', label: 'Description', type: 'string' },
    { key: 'step_id', label: 'Step', type: 'string', disabled: true },
  ]

  addDefaultTaskForm.create({
    type: 'add',
    fields: addDefaultTaskFormFields,
    data: {},
  })

  const editTaskFormFields = [
    { key: 'name', label: 'Name', type: 'string' },
    { key: 'description', label: 'Description', type: 'string' },
  ]

  editTaskForm.create({
    type: 'edit',
    fields: editTaskFormFields,
    data: {},
  })
}

async function addStep() {
  try {
    const response = await useApi.requestV2('post', 'v1/steps', addStepForm.data.value)
    if (response.status === 200) {
      showAddStepModal.value = false
      addStepForm.data.value = {}
      await refreshSteps()
    }
  } catch (error) {
    console.error('Error adding step:', error)
  }
}

function openEditTaskModal(record) {
  showEditTaskModal.value = true
  currentEditingTask.value = record
  editTaskForm.data.value = { ...record }
}

async function addDefaultTask() {
  try {
    let formData = { ...addDefaultTaskForm.data.value }
    formData.users = formData.users.length ? [formData.users] : []
    formData.deadline = formData.deadline ? formData.deadline : null
    const response = await useApi.requestV2('post', `v1/steps/${formData.step_id}/tasks`, formData)
    if (response.status === 200) {
      showAddDefaultTaskModal.value = false
      addDefaultTaskForm.data.value = {}
      await refreshSteps()
    }
  } catch (error) {
    console.error('Error adding default task:', error)
  }
}

function closeEditTaskModal() {
  showEditTaskModal.value = false
  currentEditingTask.value = null
  editTaskForm.data.value = {}
}

async function updateTask(record) {
  try {
    console.log(record)
    let formData = { ...editTaskForm.data.value }
    formData.deadline = formData.deadline ? formData.deadline : null
    const response = await useApi.requestV2('patch', `v1/steps/tasks/${currentEditingTask.value.id}`, formData)
    if (response.status === 200) {
      closeEditTaskModal()
      await refreshSteps()
    }
  } catch (error) {
    console.error('Error updating task:', error)
  }
}

function editTask(record) {
  updateTask(record)
}

async function deleteStep(id) {
  const ok = await confirm.open({
    title: 'Opmerking verwijderen?',
    message: 'Let op, de gegevens worden permanent verwijderd',
  })
  if (ok) {
    try {
      await useApi.requestV2('delete', `v1/steps/${id}`)
      await refreshSteps()
    } catch (error) {
      console.error('Error deleting step:', error)
    }
  }
}

async function deleteTask(id) {
  try {
    await useApi.requestV2('delete', `v1/steps/tasks/${id}`)
    await refreshSteps()
  } catch (error) {
    console.error('Error deleting task:', error)
  }
}

function openAddStepModal() {
  showAddStepModal.value = true
  addStepForm.data.value.project_id = route.params.projectId
}

function openAddDefaultTaskModal(step_id) {
  showAddDefaultTaskModal.value = true
  addDefaultTaskForm.data.value.step_id = step_id
}

async function refreshSteps() {
  try {
    const response = await useApi.requestV2('get', `v1/steps/projects/${route.params.projectId}`)
    steps.value = response.data.map((step) => ({
      ...step,
      default_tasks: step.default_tasks.map((task) => ({
        ...task,
        user_names: task.users.map((user) => user.name).join(', '),
      })),
    }))
  } catch (error) {
    console.error('Error refreshing steps:', error)
  }
}
</script>
